import { FC, useState, useRef, useEffect } from "react";

import { ReactComponent as ExpandIcon } from "@material-symbols/svg-400/rounded/expand_more.svg";
import cn from "classnames";
import { NavLink, To, useLocation } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";

import { DropdownProps } from "../../types";

import classes from "./Dropdown.module.scss";

import { useWindowSize } from "shared/hooks/useWindowSize";
import { RouteProps } from "shared/routes/routes";

const convertRoute = (route: RouteProps): To => {
  if (route.search) {
    const search = new URLSearchParams(route.search);
    return {
      ...route,
      search: `?${decodeURIComponent(search.toString())}`,
    };
  }
  return route as To;
};
export const Dropdown: FC<DropdownProps> = ({ className, label, count, content, setOpenBurger }) => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const location = useLocation();
  const ref = useRef(null);
  const { isSmallTablet } = useWindowSize();

  const checkActiveRoute = () => {
    const paths = content?.map((item) => item.route.pathname);
    if (paths) {
      setActive(paths.includes(location.pathname));
    }
  };

  useOnClickOutside(ref, () => {
    if (!isSmallTablet) setOpen(false);
  });

  useEffect(() => {
    checkActiveRoute();
  }, [location]);

  return (
    <div ref={ref} className={cn(classes.container, className)}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={cn(classes.label, open && classes.open, active && classes.active)}
      >
        {Boolean(count) && <div className={classes.count}>{count}</div>}
        {label}
        <ExpandIcon className={classes.icon} />
      </button>
      {open && content && (
        <div
          data-testid="dropdown-links-test"
          className={cn(classes.content, label === "Биллинг" && classes["fixed-width"])}
        >
          {content.map((link) => (
            <NavLink
              onClick={() => {
                setOpen(false);
                setOpenBurger && setOpenBurger(false);
              }}
              key={link.id}
              className={classes.link}
              to={link.route.pathname ?? ""}
            >
              {Boolean(link.hasCount && count) && <div className={classes.count}>{count}</div>}
              {link.label}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};
