import { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { useStore } from "entities/context";

import { BaseLayoutProps } from "../types";

import classes from "./BaseLayout.module.scss";

import { Background } from "widgets/Background";
import { Footer } from "widgets/Footer";
import { Header } from "widgets/Header";

import { defaultBatch } from "shared/api";
import { Container } from "shared/ui/Container";
import { Loader } from "shared/ui/Loader";
import { sendYandexMetrik } from "shared/utils";

const URLS_HIDE_BACKGROUND = [""];

export const BaseLayout: FC<BaseLayoutProps> = observer(({ children, showFooter = true, title = "Guruleads" }) => {
  const {
    userStore: { balance, contacts, user, loading, activeTickets, updateUser, updateBalance, updateActiveTickets },
    notificationStore: { updateCount },
    settingsStore: { background, updateBackground },
  } = useStore();
  useDocumentTitle(title);
  const location = useLocation();
  const [hideBackground, setHideBackground] = useState(false);
  const userId = "1";
  const balanceId = "2";
  const notificationsId = "3";
  const backgroundId = "4";
  const activeTicketsId = "5";

  const request = async () => {
    return await defaultBatch(userId, balanceId, notificationsId, backgroundId, activeTicketsId).then((res) => {
      if (res) {
        res.forEach((element) => {
          if (element.id === userId && element.result) {
            updateUser({ ...element.result, system: 1 });
          }
          if (element.id === balanceId && element.result) {
            updateBalance({ ...element.result });
          }
          if (element.id === notificationsId && element.result) {
            updateCount(element.result.count);
          }
          if (element.id === backgroundId && element.result && element.result.length !== 0) {
            updateBackground(element.result);
          }
          if (element.id === activeTicketsId && element.result) {
            updateActiveTickets(element.result);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (user === undefined || contacts === undefined || balance === undefined) {
      request();
    }
    if (URLS_HIDE_BACKGROUND.includes(location.pathname)) {
      setHideBackground(true);
    }
  }, []);
  useEffect(() => {
    sendYandexMetrik("hit");
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader icon loading size="large" absolute />
      ) : (
        <>
          {!hideBackground && background && <Background thumbnails={background?.thumbnails} link={background.link} />}
          <Header count={activeTickets ?? null} />
          <Container withBackground={!hideBackground && background !== undefined}>
            <div className={classes.container}>{children}</div>
          </Container>
          {showFooter && <Footer count={activeTickets ?? null} />}
        </>
      )}
    </>
  );
});
