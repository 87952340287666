import { FC, useState } from "react";

import { ReactComponent as MenuIcon } from "@material-symbols/svg-400/rounded/menu.svg";

import { IHeaderLeftSize } from "../../types";
import { BurgerButton } from "../BurgerButton";
import { Navigation } from "../Navigation";

import classes from "./LeftSide.module.scss";

import { Drawer } from "shared/ui/Drawer";
import { Link } from "shared/ui/Link";
import { Logo } from "shared/ui/Logo";

export const LeftSide: FC<IHeaderLeftSize> = ({ open, handleOpen, count = 0 }) => {
  return (
    <>
      <div className={classes.left}>
        <BurgerButton className={classes.burger} open={open} setOpen={handleOpen}>
          <MenuIcon className={classes.menu} />
        </BurgerButton>
        <Link to={`${process.env.REACT_APP_PUBLIC_URL}/dashboard`} className={classes.logo}>
          <Logo />
        </Link>
        <Navigation count={count ?? null} />
      </div>
      <Drawer className={classes.drawer} theme="dark" position="left" open={open} setOpen={handleOpen}>
        <Navigation mobile setOpen={handleOpen} />
      </Drawer>
    </>
  );
};
