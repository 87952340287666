import { FC } from "react";

import { TelegramIcon } from "./components/TelegramIcon";
import classes from "./Footer.module.scss";

import { Button } from "shared/ui/Button";
import { Container } from "shared/ui/Container";
import { Link } from "shared/ui/Link";
import { Logo } from "shared/ui/Logo";

interface FooterProps {
  count: number | null;
}

export const Footer: FC<FooterProps> = ({ count }) => {
  return (
    <div className={classes.footer}>
      <Container className={classes.content}>
        <div className={classes.left}>
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/dashboard`} className={classes.logo}>
            <Logo theme="gray" />
          </Link>
          <Button
            href={`${process.env.REACT_APP_PUBLIC_URL}/tickets`}
            variant="link"
            size="small"
            className={classes.help}
          >
            Служба поддержки {Boolean(count) && <div className={classes.count}>{count}</div>}
          </Button>
        </div>
        <Button
          variant="link"
          theme="secondary"
          size="small"
          href="https://t.me/guruleads"
          target="_blank"
          className={classes.info}
          iconRight={<TelegramIcon />}
        >
          Информация по офферам
        </Button>
      </Container>
    </div>
  );
};
